import RCWebContainer from '../containers/RCWeb'

const RCWeb = () => {

  return (
    <>
      <RCWebContainer />
    </>
  );
}

export default RCWeb;

